* {
  margin: 0;
}
body {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #4c4c4c;
}
form .form-input {
  margin-bottom: 10px;
}
form .form-input input,
form .form-input label,
form .form-input select {
  display: block;
}
form .form-input input,
form .form-input select,
form .form-input textarea {
  background: white;
  border: 1px solid #bdc4c9;
  font-size: 16px;
  padding: 7px 6px;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
}
form .form-input input.error,
form .form-input select.error,
form .form-input textarea.error {
  border: 1px solid #c02;
}
form .form-input input:focus,
form .form-input select:focus,
form .form-input textarea:focus {
  outline: none;
}
form .form-input span.error {
  color: red;
  line-height: 25px;
  font-size: 15px;
}
