header {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
header a {
    text-decoration: none;
}
header .logo {
  height: 50px;
  margin-right: 10px;
}
header nav.bg-transparent {
  padding: 13px 0;
}
header .navbar-brand {
  color: #fff !important;
  font-size: 24px;
}
header .navbar-nav .nav-item .nav-link {
  color: #fff;
  text-transform: uppercase;
}
header nav {
  background: #26190C;
}
header nav .content {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: -15px;
  background: #181818;
}
header nav .content .hamburger {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 20px;
  cursor: pointer;
  margin-right: 15px;
}
header nav .content .hamburger span {
  height: 2px;
  background: #fff;
  width: 30px;
}
header nav .content a.brand {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #26190C;
  padding: 10px 10px 10px 30px;
}
header nav .content a.brand:hover {
  text-decoration: none;
}
header nav .content a.brand img {
  height: 50px;
}
header nav .content a.brand h1 {
  font-size: 25px;
  margin: 0;
  color: #fff;
  padding: 0 15px;
}
header nav .content > ul {
  flex-grow: 1;
  margin: 0;
  list-style: none;
  list-style-image: none;
  list-style-type: none;
  flex-direction: row;
  justify-content: space-around;
  max-width: 700px;
  margin: 0 auto;
  display: none;
}
header nav .content ul li a {
  text-transform: uppercase;
  font-size: 15px;
  color: #222;
  font-family: "Source Sans 3", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
header nav .content ul li a:hover {
  color: #aaa;
}
header nav .side-bar {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 280px;
  background: #181818;
  z-index: 4;
  left: -280px;
  transition: left 200ms ease-in-out;
}
header nav .side-bar .hamburger {
  width: 30px;
  margin: 10px 20px 0 auto;
  cursor: pointer;
}
header nav .side-bar .hamburger svg {
  fill: rgb(153, 153, 153);
  height: 30px;
}
header nav .toggle-show {
  left: 0;
}
header nav .side-bar ul {
  flex-grow: 1;
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-image: none;
  list-style-type: none;
  display: flex;
  flex-direction: column;
}
header nav .side-bar ul li a {
  border-bottom: 1px solid rgb(48, 48, 48);
  display: block;
  padding: 30px 35px;
  line-height: 100%;
  color: rgb(153, 153, 153);
  text-transform: uppercase;
  font-size: 18px;
  transition: all 0.25s ease 0s;
  text-decoration: none;
}
@media (min-width: 1200px) {
  header nav .side-bar ul li.mobile-only {
    display: none;
  }
  header nav .content {
    background: #fff;
  }
  header nav .content ul {
    display: flex;
  }
  section.ondemand .actions {
    flex-direction: row;
  }
  header nav .content .hamburger span {
    background: #181818;
  }
}
